<script>
	import { play, pause, random } from '../stores/player.js';
	import { length, first } from '../stores/playlist.js';
	import { current } from '../stores/track.js';
	import { paused } from '../stores/audio.js';

	import Fa from './Fa.svelte';

	const iconPlay = 'circle-play';
	const iconPause = 'circle-pause';

	$: icon = $paused ? iconPlay : iconPause;
	$: track = $current || $first;
	$: disabled = ( track ) ? '' : 'disabled';

	let onclick = function(e) {
		e.preventDefault();

		if( track ) {
			play( track );
		}
	}
</script>

<button on:click={onclick} {disabled} class="btn btn-link link-light px-1"><Fa icon="{icon} fw 2x"/></button>
