import { get, writable, readable } from 'svelte/store';
import { audio } from './audio';
import { add } from './playlist';
import { current, src } from './track';

// on current track change
current.subscribe( function( track ) {

	// update audio src
	audio.src = track ? track.src : '';
	audio.currentTime = 0;
	audio.load();

	add( track );
});


export const playing = writable( false );


// player is in random mode
export const random = writable( false );

// play track, or pause if already playing
export const play = function( track, force ) {

	// check validity [MELCHIOR-C](https://go-on-web.sentry.io/issues/2492913/)
	if( audio && audio.src && track && track.src ) {

		// define a new track playing ?
		if( force || audio.src != track.src ) {
			current.set( track );
			add( track );
		}
	
		pause();
	}

}

export const playRandom = function() {
  // fetch track from window.vocab.url.random and play it
  fetch( window.vocab.url.random )
    .then( function( response ) {
      return response.json();
    })
    .then( function( track ) {
      play( track, true );
    });
}


// toggle pause state
export const pause = function() {
	if( get( playing ) ) {
		audio.pause();
		playing.set(false);
	}
	else {
		audio.play().then( function() {
			playing.set(true);
		});
	}
}
// playing.subscribe( console.log );


// stop track
export const stop = function() {

	// stop audio playing
	audio.pause();

	// set current track to null
	current.set( null );
}




// // update time while playing
// audio.addEventListener("timeupdate",function() { 
// 	time.set( audio.currentTime );
// });

// // update volume on volume change
// audio.addEventListener('volumechange', function() {
// 	volume.set( audio.volume );
// 	muted.set( audio.muted );
// });

// audio.addEventListener("canplay",function(){
// 	ready.set(true);

// 	if( !get(paused) ){
// 		audio.play();
// 	}
// });

// audio.addEventListener('emptied',function() {
// 	ready.set(false);
// });

// audio.addEventListener('pause',function() {
// 	paused.set(true);
// });


// // when audio playing ended
// audio.addEventListener("ended",function() {

// 	// play next if exists in playlist
// 	let track = next( get(playing) );
// 	if( track ) {
// 		play( track, true );
// 	}
// 	else {
// 		stop();
// 	}
// });
